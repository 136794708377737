// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.baseLayout_container__\\+jZvO {
  position: fixed;
  width: 100%;
}

.baseLayout_outlet__D2CcQ {
  width: calc(100% - 250px);
  display: flex;
  position: fixed;
  right: 0;
  /* padding-left: 10px; */
}

@media (max-width: 767px) {
  .baseLayout_outlet__D2CcQ {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/baseLayout/baseLayout.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,eAAe;EACf,QAAQ;EACR,wBAAwB;AAC1B;;AAEA;EACE;IACE,WAAW;IACX,aAAa;IACb,uBAAuB;EACzB;AACF","sourcesContent":[".container {\n  position: fixed;\n  width: 100%;\n}\n\n.outlet {\n  width: calc(100% - 250px);\n  display: flex;\n  position: fixed;\n  right: 0;\n  /* padding-left: 10px; */\n}\n\n@media (max-width: 767px) {\n  .outlet {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `baseLayout_container__+jZvO`,
	"outlet": `baseLayout_outlet__D2CcQ`
};
export default ___CSS_LOADER_EXPORT___;
