// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customNavBar_navbarContainer__9SQR3 {
    display: flex;
    height: 70px;
  }
  
  .customNavBar_logo__BkFKT {
    height: 46px;
    width: 46px;
  }
  .customNavBar_image__Dool\\+ {
    height: 40px;
    width: 40px;
  }
  
  .customNavBar_imageContainer__VnjfG {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  
  .customNavBar_profileContainer__ldeMJ {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 47px;
    width: 47px;
    border-radius: 50%;
  }
  
  .customNavBar_profileImage__uVtbh {
    width: 37px;
    height: 37px;
  }
  
  .customNavBar_navBarTitle__-hxGg{
    font-size: 2.5rem;
    font-weight: bold;
    color: #af1010;
    margin-bottom: 0px !important;
  }`, "",{"version":3,"sources":["webpack://./src/components/organisms/CustomNavBar/customNavBar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,WAAW;EACb;EACA;IACE,YAAY;IACZ,WAAW;EACb;;EAEA;IACE,aAAa;IACb,SAAS;IACT,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,iBAAiB;IACjB,iBAAiB;IACjB,cAAc;IACd,6BAA6B;EAC/B","sourcesContent":[".navbarContainer {\n    display: flex;\n    height: 70px;\n  }\n  \n  .logo {\n    height: 46px;\n    width: 46px;\n  }\n  .image {\n    height: 40px;\n    width: 40px;\n  }\n  \n  .imageContainer {\n    display: flex;\n    gap: 20px;\n    align-items: center;\n  }\n  \n  .profileContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    height: 47px;\n    width: 47px;\n    border-radius: 50%;\n  }\n  \n  .profileImage {\n    width: 37px;\n    height: 37px;\n  }\n  \n  .navBarTitle{\n    font-size: 2.5rem;\n    font-weight: bold;\n    color: #af1010;\n    margin-bottom: 0px !important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarContainer": `customNavBar_navbarContainer__9SQR3`,
	"logo": `customNavBar_logo__BkFKT`,
	"image": `customNavBar_image__Dool+`,
	"imageContainer": `customNavBar_imageContainer__VnjfG`,
	"profileContainer": `customNavBar_profileContainer__ldeMJ`,
	"profileImage": `customNavBar_profileImage__uVtbh`,
	"navBarTitle": `customNavBar_navBarTitle__-hxGg`
};
export default ___CSS_LOADER_EXPORT___;
