// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ActivityLoader_modalContainer__kLF\\+o {
    overflow: hidden !important;
    background-color: #fff;
    opacity: 0.5 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/atom/ActivityLoader/css/ActivityLoader.module.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,uBAAuB;AAC3B","sourcesContent":[".modalContainer {\n    overflow: hidden !important;\n    background-color: #fff;\n    opacity: 0.5 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": `ActivityLoader_modalContainer__kLF+o`
};
export default ___CSS_LOADER_EXPORT___;
