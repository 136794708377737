// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/ProfileEdit.module.css */

.Profile_form__GjsBp {
    max-width: 600px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .Profile_form__GjsBp label {
    font-weight: bold;
  }
  
  .Profile_form__GjsBp .Profile_btn-primary__-hlOi {
    width: 100%;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/pages/Profile/Profile.module.css"],"names":[],"mappings":"AAAA,0CAA0C;;AAE1C;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;EACf;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,WAAW;EACb","sourcesContent":["/* src/components/ProfileEdit.module.css */\n\n.form {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 2rem;\n  }\n  \n  .form label {\n    font-weight: bold;\n  }\n  \n  .form .btn-primary {\n    width: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `Profile_form__GjsBp`,
	"btn-primary": `Profile_btn-primary__-hlOi`
};
export default ___CSS_LOADER_EXPORT___;
